<script>

import AdvancedTable from "../../../../components/elements/advanced-table.vue";
import StatusBadge from "../../../../components/elements/status-badge.vue";
import Swal from "sweetalert2";
import {mapGetters} from "vuex";

export default {
    components: {
        StatusBadge,
        AdvancedTable,
    },

    computed: {
        ...mapGetters({
            configuration: 'app/configuration'
        }),
        fields: function () {
            const fields = [
                {
                    key: "name",
                    label: this.$t('tasks::tasks.columns.name').ucFirst(),
                    formatter: (value, index, item) => item.translation_key ? this.$t(item.translation_key).ucFirst() : value,
                    thClass: '',
                    tdClass: 'font-weight-bold',
                    sortable: true
                }
            ];

            if (this.configuration?.tasks.enable_priorities) {
                fields.push({
                    key: "priority",
                    label: this.$t('tasks::tasks.relationships.priority').ucFirst(),
                    formatter: (value, index, item) => value ? value : item.priority_id,
                    thClass: '',
                    sortable: true
                })
            }
            if (this.configuration?.tasks.enable_statuses) {
                fields.push({
                    key: "status",
                    label: this.$t('tasks::tasks.relationships.status').ucFirst(),
                    formatter: (value, index, item) => value ? value : item.status_id,
                    thClass: '',
                    sortable: true
                })
            }
            return [
                ...fields,
                {
                    key: "task",
                    label: this.$t('tasks::tasks.relationships.task').ucFirst(),
                    formatter: (value, index, item) => value ? value : item.task_id,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "assigned",
                    label: this.$t('tasks::tasks.relationships.assigned').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "client",
                    label: this.$t('tasks::tasks.relationships.client').ucFirst(),
                    formatter: (value) => value?.name,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "type",
                    label: this.$t('tasks::tasks.relationships.type').ucFirst(),
                    formatter: (value, index, item) => value ? (value.translation_key ? this.$t(value.translation_key) : value.name) : item.type_id,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "deadline",
                    label: this.$t('tasks::tasks.columns.deadline').ucFirst(),
                    formatter: (value) => value?.printDate(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: 'text-end',
                    sortable: false
                },
            ];
        },
    },

    methods: {
        close: function (id) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.confirm_title'),
                text: this.$t('base.confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-warning border-warning',
                    confirmButton: 'btn btn-warning',
                    cancelButton: 'btn btn-warning'
                },
                confirmButtonText: this.$t('base.close_it').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('task/close', {id: id, query: this.query});
                }
            });
        },
    },

    data() {
        return {
            query: { with: ['priority', 'status', 'type', 'assigned', 'client'] }
        };
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'tasks::tasks.task', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <advanced-table
        :caption="$tc('tasks::tasks.task', 2).ucFirst()"
        :create-link="{ name: 'my-task-create' }"
        :current-page="1"
        :edit-link="{ name: 'my-task-edit' }"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :queries="query"
        :per-page="10"
        borderless
        deletable
        editable
        hover
        action="own"
        module-pager="ownPager"
        moduleNamespace="task"
        outlined
        permission="tasks"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="id"
        sort-direction="asc"
        sort-icon-left
        archive
    >
        <template #more-action="{item}">
            <li v-if="!item.closed_at" class="list-inline-item">
                <a
                    @click.prevent="close(item.id)"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('base.close_it').ucFirst()"
                    class="px-2 text-info cursor-pointer"
                ><i class="bx bx-check font-size-18"></i
                ></a>
            </li>
        </template>
        <template #assigned="{ value }">
            <div class="avatar-group-item">
                <a v-if="value?.image && value?.image?.path" href="javascript: void(0);" class="d-inline-block">
                    <img :src="value.image.path"
                         :alt="value.name"
                         :title="value.name"
                         class="rounded-circle avatar-sm">
                </a>
                <div v-else class="avatar-sm cursor-pointer">
                    <span :title="value?.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ value?.name?.firstLetters() }} </span>
                </div>
            </div>
        </template>
        <template #priority="{ value }">
            <span v-if="value" :style="{color: value.color}" class="me-2">
                <i :class="value.icon"></i>
            </span>
            <status-badge :bg-color="value?.color">{{ value?.translation_key ? $t(value.translation_key) : value?.name }}</status-badge>
        </template>
        <template #status="{ item, value }">
            <template v-if="item.closed_at">
                <i class="bx bx-lock text-dark"></i>
            </template>
            <template v-else>
                <span v-if="value?.icon" :style="{color: value.color}" class="me-2">
                    <i :class="value.icon"></i>
                </span>
                <status-badge :bg-color="value?.color">{{ value?.translation_key ? $t(value.translation_key) : value?.name }}</status-badge>
            </template>
        </template>
    </advanced-table>
</template>
